<template>
    <validation-provider
        ref="provider"
        v-slot="{ errors, invalid, validated }"
        slim
        :rules="rules"
        :name="fieldName"
        :vid="$attrs.vid"
        :debounce="$attrs.debounce"
        :custom-messages="customMessages"
    >
        <v-textarea
            v-if="textarea"
            filled
            :error-messages="observed ? [] : errors"
            :error="validated && invalid"
            :required="required"
            :counter="counterText"
            :counter-value="count"
            class="a-textarea"
            v-bind="attrs"
            v-on="$listeners"
            @paste="onPaste"
        >
            <template v-if="htmlHint" #message="{ message }">
                <span v-html="message"></span>
            </template>
            <slot v-for="(_, slot) in $slots" :slot="slot" :name="slot"></slot>
        </v-textarea>
        <v-text-field
            v-else
            filled
            :error-messages="observed ? [] : errors"
            :error="validated && invalid"
            :required="required"
            :counter="counterText"
            :counter-value="count"
            v-bind="attrs"
            v-on="$listeners"
            @paste="onPaste"
        >
            <template v-if="htmlHint" #message="{ message }">
                <span v-html="message"></span>
            </template>
            <slot v-for="(_, slot) in $slots" :slot="slot" :name="slot"></slot>
        </v-text-field>
    </validation-provider>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { ValidationProvider } from 'vee-validate';

const ATextInputProps = Vue.extend({
    name: 'ATextInput',
    props: {
        label: {
            type: String,
            default() {
                return '';
            }
        },
        field: {
            type: String,
            default() {
                return '';
            }
        },
        name: {
            type: String,
            default() {
                return '';
            }
        },
        rules: {
            type: [String, Object],
            default() {
                return '';
            }
        },
        textarea: {
            type: Boolean,
            default() {
                return false;
            }
        },
        htmlHint: {
            type: Boolean,
            default() {
                return false;
            }
        },
        observed: {
            type: Boolean,
            default() {
                return false;
            }
        },
        disabled: {
            type: Boolean,
            default() {
                return false;
            }
        },
        counter: {
            type: [String, Boolean, Number],
            default: false // eslint-disable-line quote-props
        },
        counterValue: {
            type: Function,
            default: null // eslint-disable-line quote-props
        },
        customMessages: {
            type: Object,
            default() {
                return {};
            }
        },
        hideLabel: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component({
    inheritAttrs: false,
    components: {
        ValidationProvider
    }
})
export default class ATextInput extends ATextInputProps {
    $refs!: {
        provider: InstanceType<typeof ValidationProvider>;
    };

    get attrs() {
        return {
            label: this.labelAltered,
            disabled: this.disabled,
            ...this.$attrs
        };
    }

    get labelAltered() {
        if (this.hideLabel) {
            return '';
        }
        return `${this.label}${this.required && !this.disabled ? ' *' : ''}`;
    }

    get fieldName() {
        return this.field || this.label || this.name;
    }

    get required() {
        return typeof this.rules === 'string'
            ? this.rules?.includes('required')
            : Object.keys(this.rules).includes('required');
    }

    get max() {
        return (
            (typeof this.rules === 'string'
                ? this.rules
                      .split('|')
                      .find(rule => rule.split(':')[0] === 'max')
                      ?.split(':')[1]
                : this.rules.max) || void 0
        );
    }

    get counterText() {
        return this.counter || Boolean(this.counterValue) || this.max || false;
    }

    count(value?: string) {
        if (typeof this.counterValue === 'function') {
            return this.counterValue(value);
        }

        return value ? value.length : 0;
    }

    onPaste(e: ClipboardEvent) {
        // cancel default action
        e.preventDefault();
        // get clipboard value
        const text = e.clipboardData?.getData('text/plain');

        if (text) {
            // insert cleared text, remove CR chars
            document.execCommand('insertText', false, text.replace(/\r/gm, ''));
        }
    }
}
</script>

<style lang="scss" scoped>
.a-textarea::v-deep {
    textarea {
        max-height: 35vh !important;
        overflow: auto !important;
    }
}
</style>
