export default function isClientCabin() {
    if (
        /qa[0-9]+clientcabin\.buzztiger\.com/gi.test(
            window.location.hostname
        ) ||
        /clientcabin\.com/gi.test(window.location.hostname) ||
        !(
            /qa[0-9]+ampifire\.buzztiger\.com/gi.test(
                window.location.hostname
            ) || /ampifire\.com/gi.test(window.location.hostname)
        )
    ) {
        return true;
    }

    return false;
}
