import { extend } from 'vee-validate';

type NotEqualsValidationParams = {
    target: string;
};

extend('not_equals', {
    params: ['target'],
    validate(value: string, params) {
        if (!value) {
            return true;
        }

        const { target } = params as NotEqualsValidationParams;

        return value !== target;
    },
    message: '{_field_} may not be the same as {target}'
});
