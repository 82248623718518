<template>
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M10 2L10.7366 4.65306C11.3592 6.8957 13.0557 8.68222 15.2633 9.41975L17 10L15.2633 10.5802C13.0557 11.3178 11.3592 13.1043 10.7366 15.3469L10 18L9.26344 15.3469C8.64083 13.1043 6.94426 11.3178 4.73674 10.5802L3 10L4.73674 9.41975C6.94426 8.68222 8.64083 6.8957 9.26344 4.65306L10 2Z"
            fill="url(#paint0_linear_14914_4089)"
        />
        <defs>
            <linearGradient
                id="paint0_linear_14914_4089"
                x1="14.5"
                y1="7.5"
                x2="6.08799"
                y2="14.2895"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#2FA9EB" />
                <stop offset="0.449886" stop-color="#26CED1" />
            </linearGradient>
        </defs>
    </svg>
</template>

<script>
export default {
    name: 'Sparkle'
};
</script>
