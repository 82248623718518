<template>
    <v-container>
        <v-row class="pa-0" justify="center">
            <v-col cols="12" lg="9" xl="7" class="pa-0">
                <v-container class="clientcabin clientcabin-footer">
                    <v-row justify="space-between">
                        <v-col cols="6" sm="auto" class="footer-group">
                            <span class="text-h6">Also See</span>
                            <ul
                                class="mt-3 text-body-1"
                                :class="{ two: $vuetify.breakpoint.smAndUp }"
                            >
                                <li>
                                    <router-link to="/reasons">
                                        Results & Reasons
                                    </router-link>
                                </li>
                                <li>
                                    <router-link to="/about">
                                        About Us
                                    </router-link>
                                </li>
                                <li v-if="shouldShowBooking">
                                    <router-link to="/book">
                                        Book a Call
                                    </router-link>
                                </li>
                                <li v-for="(link, index) in links" :key="index">
                                    <a :href="link.url" target="_blank">
                                        {{ link.label }}
                                    </a>
                                </li>
                            </ul>
                        </v-col>
                        <v-col
                            v-if="hasSocialLinks"
                            cols="6"
                            sm="auto"
                            class="footer-group"
                        >
                            <span class="text-h6">Stay Connected</span>
                            <ul class="mt-3 text-body-1">
                                <li v-if="facebookLink">
                                    <a :href="facebookLink" target="_blank">
                                        <v-icon left>
                                            fab fa-facebook-square
                                        </v-icon>
                                        <span>Facebook</span>
                                    </a>
                                </li>
                                <li v-if="linkedinLink">
                                    <a :href="linkedinLink" target="_blank">
                                        <v-icon left>fab fa-linkedin</v-icon>
                                        <span>LinkedIn</span>
                                    </a>
                                </li>
                                <li v-if="twitterLink">
                                    <a :href="twitterLink" target="_blank">
                                        <v-icon left>
                                            fab fa-square-x-twitter
                                        </v-icon>
                                        <span>Twitter</span>
                                    </a>
                                </li>
                            </ul>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            align-self="center"
                            class="footer-group pt-lg-8 mb-n5 text-center boulder--text text-body-1"
                        >
                            <router-link class="mr-2" to="/terms">
                                Terms of Service
                            </router-link>
                            |
                            <router-link class="ml-2" to="/terms#privacy">
                                Privacy Policy
                            </router-link>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            align-self="center"
                            class="text-center boulder--text text-body-1"
                        >
                            © {{ currentYear }} {{ options?.business_name }}.
                            All rights reserved.
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
    </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { InjectReactive } from '@/utils/decorators';

import type { ClientCabin } from '@/types/ClientCabin';

@Component
export default class ClientCabinFooter extends Vue {
    @InjectReactive({
        from: 'options',
        default() {
            return false;
        }
    })
    options!: Partial<ClientCabin> | null;

    get currentYear() {
        return new Date().getFullYear();
    }

    get links() {
        const links = this.options?.options?.footer?.links || [];

        if (!links.length) {
            if (
                this.options?.options?.footer?.link_text &&
                this.options?.options?.footer?.link_url
            ) {
                links.push({
                    label: this.options?.options?.footer?.link_text,
                    url: this.options?.options?.footer?.link_url
                });
            }
        }

        return links.filter(link => link.label && link.url);
    }

    get facebookLink() {
        return this.options?.social_media?.facebook;
    }

    get twitterLink() {
        return this.options?.social_media?.twitter;
    }

    get linkedinLink() {
        return this.options?.social_media?.linkedin;
    }

    get hasSocialLinks() {
        return !![
            this.facebookLink,
            this.twitterLink,
            this.linkedinLink
        ].filter(Boolean).length;
    }

    get shouldShowBooking() {
        return (
            this.options?.reseller_booking_url && this.$route.path !== '/book'
        );
    }
}
</script>

<style lang="scss" scoped>
.clientcabin-footer::v-deep {
    * {
        font-family:
            'Segoe UI', '-apple-system', BlinkMacSystemFont, Roboto,
            'Helvetica Neue', Helvetica, Ubuntu, Arial, sans-serif !important;
    }
    .footer-group {
        color: #282b30;

        ul {
            list-style: none;
            padding-left: 0;

            &.two {
                columns: 2;
                column-gap: 3em;
            }

            li {
                padding-top: 0.7em;
            }
        }

        a {
            color: #282b30;
            transition: all 0.15s ease-out;
            text-decoration: none;

            &:hover {
                color: $primary-color;
            }

            &.router-link-active {
                color: $primary-color;
                pointer-events: none;
            }
        }
    }
}
</style>
