import axios from 'axios';
import type { InternalCacheRequestConfig } from 'axios-cache-interceptor';

import { Module } from 'vuex';
import { Ability } from '@casl/ability';

import { getRulesFor } from './Ability';

import { RootState } from '@/store/State';
import { state, UserState } from './State';
import { RolesMap } from './Role';

interface LoginOptions {
    login: string;
    password: string;
    autoLogin?: boolean | number;
    nextUrl?: string;
}

interface ResetOptions {
    email: string;
}

interface ApiResponse extends UserState {
    resellerBusinessName: string | null;
    resellerContactEmail: string | null;
}

let prober: Promise<void> | null = null;

const activeSessionCookieName = 'active_session';

const VIPResellerIds = [12674, 29336, 35113, 37869, 43673, 43775, 43899];

function redirect(url: string) {
    window.location.href = url;
}

const User: Module<UserState, RootState> = {
    namespaced: true,

    state() {
        return state;
    },

    getters: {
        hasActiveSession(state) {
            return state.isAuthorized;
        },

        authenticated(state) {
            return state.isAuthenticated;
        },

        ability() {
            return new Ability();
        },

        isClient(state) {
            return ['Reseller', 'Customer', 'Affiliate'].includes(state.role);
        },

        isEditor(state, getters) {
            return RolesMap.Editor.includes(state.role) || getters.isAdmin;
        },

        isAuthor(state, getters) {
            return RolesMap.Author.includes(state.role) || getters.isAdmin;
        },

        isAdmin(state) {
            return RolesMap.Admin.includes(state.role);
        },

        isReseller(state) {
            return RolesMap.Reseller.includes(state.role);
        },

        isVIPReseller(state) {
            return VIPResellerIds.includes(state.id);
        }
    },

    mutations: {
        loading(state, isLoading = true) {
            state.isLoading = isLoading;
        },

        authorized(
            state,
            {
                id,
                role,
                firstname,
                lastname,
                email,
                fullUserName,
                isLociCycleBuyer,
                isAsigoBuyer,
                isDropServeBuyer,
                isNewDropServeBuyer,
                isNewSimplerTrafficBuyer,
                isBoringMethodBuyer,
                isPressCableUser,
                isKamManagedUser,
                hasPressCableCredits,
                hasAiFeatures
            }: ApiResponse
        ) {
            if (id) {
                state.id = id;
                state.role = role;
                state.isAuthorized = true;

                state.firstname = firstname;
                state.lastname = lastname;
                state.email = email;

                state.fullUserName = fullUserName;

                state.isLociCycleBuyer = isLociCycleBuyer;
                state.isAsigoBuyer = isAsigoBuyer;
                state.isDropServeBuyer = isDropServeBuyer;
                state.isNewDropServeBuyer = isNewDropServeBuyer;
                state.isNewSimplerTrafficBuyer = isNewSimplerTrafficBuyer;
                state.isBoringMethodBuyer = isBoringMethodBuyer;

                state.isPressCableUser = isPressCableUser;
                state.isKamManagedUser = isKamManagedUser;
                state.hasPressCableCredits = hasPressCableCredits;

                state.hasAiFeatures = hasAiFeatures;
            }
        },

        unauthorized(state) {
            state.isAuthorized = false;
        },

        authenticated(state) {
            state.isAuthenticated = true;
        },
        // system-wide state, always available
        system(state, data?: Partial<ApiResponse>) {
            if (data) {
                const {
                    systemMessages,
                    resellerBusinessName,
                    resellerContactEmail
                } = data;

                state.systemMessages = systemMessages || [];

                state.businessName =
                    resellerBusinessName ||
                    (window.location.host.includes('clientcabin')
                        ? 'Your Platform'
                        : 'Ampifire');

                state.businessEmail = resellerContactEmail || '';
            }
        }
    },

    actions: {
        init({ getters }) {
            const ability = getters.ability;

            ability.update([]);

            this.subscribe((mutation, state) => {
                if (mutation.type === 'user/authorized') {
                    if (state.user) {
                        ability.update(
                            getRulesFor({
                                kind: 'User',
                                id: state.user.id,
                                role: state.user.role
                            })
                        );
                    } else {
                        ability.update([]); // reset all abilities
                    }
                }

                if (mutation.type === 'user/unauthorized') {
                    ability.update([]);
                }
            });
        },

        async authorize({ dispatch }) {
            if (prober === null) {
                const shouldProbe = await dispatch('shouldProbe');

                if (shouldProbe) {
                    prober = dispatch('getProber');
                }
            }

            return prober;
        },

        shouldProbe({ state }) {
            const isCookieSet =
                RegExp(`(^|;)\\s*${activeSessionCookieName}\\s*=\\s*([^;]+)`)
                    .exec(document.cookie)
                    ?.pop() || '';

            const mostLikelyLoggedOut = state.isAuthorized && !isCookieSet;

            const newActiveSession = !state.isAuthorized && isCookieSet;

            return mostLikelyLoggedOut || newActiveSession
                ? true
                : !isCookieSet;
        },

        async getProber({ commit }) {
            commit('loading', true);

            return (
                prober ??
                axios
                    .get('/users/active_session', {
                        cache: false
                    } as InternalCacheRequestConfig)
                    .then(({ data }) => {
                        commit('system', data.data);

                        if (data.data?.active) {
                            commit('authorized', data.data);
                        } else {
                            commit('unauthorized');
                        }
                    })
                    .catch(() => {
                        commit('unauthorized');
                    })
                    .finally(() => {
                        prober = null;
                        commit('authenticated');
                        commit('loading', false);
                    })
            );
        },

        async login(
            { commit, dispatch },
            { login, password, autoLogin, nextUrl }: LoginOptions
        ) {
            commit('loading', true);

            return axios
                .post('/users/login', {
                    login,
                    password,
                    auto_login: autoLogin ? 1 : 0
                })
                .then(({ data }) => {
                    if (!data.meta.success) {
                        return Promise.reject(
                            Array.isArray(data.meta.errors)
                                ? data.meta.errors[0]
                                : data.meta.errors
                        );
                    } else {
                        if (data.data.redirect && !nextUrl) {
                            return redirect(data.data.redirect);
                        }

                        return dispatch('authorize');
                    }
                })
                .catch(error => {
                    commit('unauthorized');
                    return Promise.reject(error);
                })
                .finally(() => {
                    commit('authenticated');
                    commit('loading', false);
                });
        },

        async logout({ commit }) {
            commit('loading', true);

            return axios
                .get('/users/logout', {
                    cache: false
                } as InternalCacheRequestConfig)
                .catch(() => {
                    commit('unauthorized');
                })
                .finally(() => {
                    commit('unauthorized');
                    commit('loading', false);
                });
        },

        forceLogout({ commit }) {
            commit('unauthorized');
        },

        async reset({ commit }, { email }: ResetOptions) {
            commit('loading', true);

            return axios
                .post('/users/password_reset_send_confirmation', {
                    email
                })
                .then(({ data }) => {
                    if (!data.meta.success) {
                        return Promise.reject(
                            Array.isArray(data.meta.errors)
                                ? data.meta.errors[0]
                                : data.meta.errors
                        );
                    }
                })
                .finally(() => {
                    commit('loading', false);
                });
        }
    }
};

export default User;
