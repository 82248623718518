import Vue from 'vue';
import { InjectReactive } from '@/utils/decorators';
import Component, { mixins } from 'vue-class-component';

import {
    AppConnectionCookie,
    Company,
    CompanyAccountConnection,
    DistributionSites
} from '@/types/Company';

import { Endpoint } from '@/mixins';
import popupCentered from '@/utils/helpers/popupCentered';

const ConnectSocialBuAppProps = Vue.extend({
    props: {
        companyId: {
            type: Number,
            default() {
                return 0;
            }
        }
    }
});

@Component
export default class ConnectSocialBuApp extends mixins(
    ConnectSocialBuAppProps,
    Endpoint
) {
    popup: Window | null = null;

    isLinking = false;
    isVerifying = false;
    isMounted = false;

    interval = 5000;

    verificationTimeout: ReturnType<typeof setTimeout> | void = void 0;

    siteId = 0;
    endpoint = '/companies/view';

    type = '';

    get sourceUrl() {
        return [this.endpoint, this.companyId].join('/');
    }

    @InjectReactive({
        from: 'onBeforeLeave',
        default() {
            return async () => void 0;
        }
    })
    onBeforeLeave!: () => Promise<void>;

    onMounted() {
        this.isMounted = true;
    }

    beforeDestroy() {
        this.abort();

        this.isMounted = false;
    }

    setLinking(isLinking = true) {
        this.isLinking = isLinking;
    }

    setVerifying(isVerifying = true) {
        this.isVerifying = isVerifying;

        if (!isVerifying) {
            clearTimeout(this.verificationTimeout as number);
        }
    }

    async link() {
        this.setLinking();

        return this.$http
            .post('/socialbu/getConnectLink', {
                company_id: this.companyId,
                distribution_site_id: this.siteId,
                type: this.type
            })
            .then(({ data }) => {
                if (data.error) {
                    throw new Error(data.error);
                } else {
                    this.authorize(data.connect_url);
                }
            })
            .catch(() => {
                this.$store.dispatch(
                    'notification/error',
                    `Unable to get ${DistributionSites[this.siteId]} authorization URL. Please try again later or contact support.`
                );

                this.setLinking(false);
            });
    }

    async authorize(url: string) {
        await this.onBeforeLeave();

        this.$cookies.set(
            AppConnectionCookie,
            {
                site: this.siteId
            },
            0 // session cookie
        );
        // open authorization URL
        this.popup = window.open(
            url,
            `Connect ${DistributionSites[this.siteId]} Account`,
            [
                'menubar=no',
                'location=no',
                'status=no',
                'titlebar=no',
                'toolbar=no',
                popupCentered()
            ].join(',')
        );

        if (this.popup) {
            this.popup.focus();
        }

        this.verify();
    }

    onData(data: { company: Company }) {
        if (data.company) {
            if (this.verifyConnection(data.company.company_oauths)) {
                this.onConnection();

                return this.close();
            }
        }

        this.verify();
    }

    cancel() {
        this.abort();

        this.close();

        this.$emit('cancelled');
    }

    close() {
        this.reset();

        this.$store.dispatch('modal/close', `connect-${this.type}`);
    }

    reset() {
        this.$cookies.remove(AppConnectionCookie);

        this.setLinking(false);
        this.setVerifying(false);
    }

    verify() {
        this.setVerifying();

        this.verificationTimeout = setTimeout(() => {
            if (this.isMounted) {
                this.load();
            }
        }, this.interval);
    }

    verifyConnection(connections: CompanyAccountConnection[]) {
        const connection = connections.find(
            connection => connection.distribution_site_id === this.siteId
        );

        if (connection) {
            return Boolean(connection.socialbu);
        }

        return false;
    }

    onConnection() {
        this.$store.dispatch(
            'notification/success',
            `${DistributionSites[this.siteId]} account connected successfully`
        );

        this.$emit('connected');
    }
}
